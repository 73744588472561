<template>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>Filters</h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Name</label>
                    <b-form-input id="name" v-model="form.name.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Arabic Name</label>
                    <b-form-input id="name" v-model="form.name_ar.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Username</label>
                    <b-form-input id="name" v-model="form.user_name.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>ٍEmail</label>
                    <b-form-input id="name" v-model="form.email.val" class="d-inline-block mr-1" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Role</label>
                    <v-select v-model="form.role_id.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="getRoles" label="name" :clearable="true" input-id="role" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Country</label>
                    <v-select v-model="form.country_id.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="getCountries" label="name" :clearable="true" input-id="country" @option:selected="changeCountry" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>City</label>
                    <v-select v-model="form.city_id.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="cities" :clearable="true" label="name" input-id="city" />
                </b-col>
                <b-col cols="12" md="6" class="mb-md-1 mb-2">
                    <label>Verify Status</label>
                    <v-select v-model="form.have_verify_tick.val" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="verifyStatus" :clearable="true" label="name" input-id="verify-status" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mb-2">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="button" variant="danger" class="mt-1" @click="filterUsers"> Filter </b-button>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import useUsersList from "@/views/apps/user/useUsersList";
import { avatarText } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import CityRepository from "@/abstraction/repository/cityRepository";
const cityRepository = new CityRepository();

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BFormInput,
        BButton,
        vSelect,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters("country", ["getCountries"]),
        ...mapGetters("role", ["getRoles"]),
    },
    props: {
        roleFilter: {
            type: [String, null],
            default: null,
        },
        planFilter: {
            type: [String, null],
            default: null,
        },
        statusFilter: {
            type: [String, null],
            default: null,
        },
        roleOptions: {
            type: Array,
            required: true,
        },
        planOptions: {
            type: Array,
            required: true,
        },
        statusOptions: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const isLoading = ref();
        const {
            fetchUsers,
            tableColumns,
            perPage,
            pagination,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            users,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        } = useUsersList();

        const blankForm = {
            name: {
                type: "like",
                val: null,
            },
            name_ar: {
                type: "like",
                val: null,
            },
            user_name: {
                type: "like",
                val: null,
            },
            email: {
                type: "like",
                val: null,
            },
            country_id: {
                type: "like",
                val: null,
            },
            role_id: {
                type: "like",
                val: null,
            },
            city_id: {
                type: "like",
                val: null,
            },
            have_verify_tick: {
                type: "like",
                val: null,
            },
        };
        const verifyStatus = ref([
            { id: 0, name: "Not Verified" },
            { id: 1, name: "Verified" },
        ]);
        const cities = ref([]);
        const form = ref(blankForm);

        return {
            form,
            isLoading,
            // Sidebar
            fetchUsers,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            users,
            cities,
            verifyStatus,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,
        };
    },
    methods: {
        ...mapActions("country", ["loadCountries"]),
        ...mapActions("role", ["loadRoles"]),
        async changeCountry() {
            if (this.form.country_id.val) {
                const resoruce = await cityRepository.index(this.form.country_id.val);
                this.cities = resoruce.data;
            }
        },
        filterUsers() {
            console.log(this.form);
            this.$emit("filter", { page: 1, itemsPerPage: this.perPage }, this.form);
        },
    },
    created() {
        this.loadCountries();
        this.loadRoles();
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
