<template>
    <div>
        <div v-if="hasPermission">
            <!-- <div> -->
            <!-- Filters -->
            <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter" :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" @filter="fetchUsers" />

            <!-- Table Container Card -->
            <b-card no-body class="mb-0">
                <div class="m-2">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <b-form-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" @change="changePageUsers({ page: 1, itemsPerPage: $event })" />
                            <label>entries</label>
                        </b-col>
                        <!-- <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                            <b-form-group label="Sort" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
                                <b-input-group size="sm">
                                    <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                                        <template v-slot:first>
                                            <option value="">-- none --</option>
                                        </template>
                                    </b-form-select>
                                    <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col> -->
                    </b-row>
                </div>
                <!-- :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  -->
                <b-table ref="refUserListTable" class="position-relative pb-3" :items="users" responsive :fields="tableColumns" :per-page="perPage" primary-key="id" show-empty empty-text="No matching records found">
                    <!-- Column: User -->
                    <template #cell(user)="data">
                        <b-media vertical-align="center" class="align-items-center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.thumbnail" :text="avatarText(data.item.name)" :variant="`light-${resolveUserRoleVariant(data.item.role)}`" :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
                            </template>
                            <b-link :to="{ name: 'apps-users-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
                                {{ data.item.name }}
                            </b-link>
                            <small class="text-gray font-weight-bolder">{{ data.item.country_code }}</small
                            >-
                            <small class="text-gray">{{ data.item.cell_number }}</small>
                        </b-media>
                    </template>
                    <template #cell(username)="data">
                        <div class="text-nowrap">
                            <span class="font-weight-bolder">{{ data.item.user_name }}</span>
                        </div>
                    </template>
                    <!-- Column: Role -->
                    <template #cell(role)="data">
                        <div class="text-nowrap">
                            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50" :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
                            <span class="text-capitalize">{{ data.item.role }}</span>
                        </div>
                    </template>
                    <template #cell(verify-tick)="data">
                        <!-- <div class="text-nowrap" v-if="data.item.have_verify_tick == 0">
                            <span class="font-weight-bolder text-danger">Not Verified</span>
                        </div>
                        <div class="text-nowrap" v-else>
                            <span class="font-weight-bolder text-success">Verified</span>
                        </div> -->
                        <div v-if="data.item.badges.length < 1">
                            <b-button @click="editVerifyTickHandler(data.item.id)" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon"> <feather-icon icon="EditIcon" />Assign</b-button>
                        </div>
                        <div v-else class="cursor-pointer" @click="editVerifyTickHandler(data.item.id)">
                            <b-img v-if="data.item.badges.includes('Celebrity')" class="mb-1 mb-sm-0" height="20" src="./images/celebrity.png" alt="Left image" />
                            <b-img v-if="data.item.badges.includes('Corporate')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/corporate.png" alt="Left image" />
                            <b-img v-if="data.item.badges.includes('Fashionista')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/fashionista.png" alt="Left image" />
                            <b-img v-if="data.item.badges.includes('Verification')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/Badge.png" alt="Left image" />
                            <b-img v-if="data.item.badges.includes('VIP')" class="mb-1 mb-sm-0 ml-1" height="20" src="./images/vip.png" alt="Left image" />
                        </div>
                    </template>
                    <!-- Column: Actions -->

                    <template #cell(actions)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>
                            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
                                <feather-icon icon="FileTextIcon" />
                                <span class="align-middle ml-50">Details</span>
                            </b-dropdown-item>

                            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Edit</span>
                            </b-dropdown-item>

                            <b-dropdown-item :to="{ name: 'apps-user-change-password', params: { id: data.item.id } }">
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">Change Password</span>
                            </b-dropdown-item>

                            <b-dropdown-item>
                                <feather-icon icon="MailIcon" />
                                <span class="align-middle ml-50" v-b-modal.send-email-modal ref="send-email-modal" @click="openSendEmailModal(data.item.id)">Send Email</span>
                            </b-dropdown-item>

                            <b-dropdown-item @click="deleteUser(data.item.id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">Delete</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <!-- <template #cell(edit-row)="data">
                        <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </template> -->
                    <template #cell(created_at)="data">
                        <small>{{ data.item.created_at }}</small>
                    </template>
                </b-table>
                <b-modal v-model="editModal" title="Edit Verify Tick" ok-title="Close" ok-only>
                    <b-form-group class="mb-2" label="Verify Tick" label-for="verify_tick">
                        <v-select v-model="form.badges" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="badges" :clearable="true" multiple />
                    </b-form-group>
                    <div class="text-center">
                        <b-img v-if="form.badges.includes('Celebrity')" class="mb-1 mb-sm-0" height="30" src="./images/celebrity.png" alt="Left image" />
                        <b-img v-if="form.badges.includes('Corporate')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/corporate.png" alt="Left image" />
                        <b-img v-if="form.badges.includes('Fashionista')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/fashionista.png" alt="Left image" />
                        <b-img v-if="form.badges.includes('Verification')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/Badge.png" alt="Left image" />
                        <b-img v-if="form.badges.includes('VIP')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/vip.png" alt="Left image" />
                    </div>
                    <div class="text-center mt-3">
                        <b-button @click="updateVerifyTick" v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-success" class="btn-icon"> Update </b-button>
                    </div>
                </b-modal>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of {{ pagination.itemsLength }} entries</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination v-model="currentPage" :total-rows="pagination.itemsLength" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="changePageUsers({ page: $event, itemsPerPage: perPage })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
            <template>
                <div>
                    <send-email v-if="isModal('modal_send_email')" :user-id="userId"></send-email>
                </div>
            </template>
        </div>
        <b-card v-else class="text-center">
            <h1>⛔ You are not Allowed to be here ⛔</h1>
        </b-card>
    </div>
</template>

<script>
import { BInputGroup, BFormGroup, BModal, BImg, BCard, BRow, BCol, BFormInput, BFormSelect, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import UserRepository from "@/abstraction/repository/userRepository";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import SendEmail from "@/views/apps/email/SendEmail";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";

const userRepository = new UserRepository();
export default {
    components: {
        BInputGroup,
        BFormGroup,
        UsersListFilters,
        BModal,
        BImg,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        SendEmail,

        vSelect,
    },
    directives: {
        Ripple,
    },
    setup() {
        const roleOptions = [
            { label: "Admin", value: "admin" },
            { label: "Author", value: "author" },
            { label: "Editor", value: "editor" },
            { label: "Maintainer", value: "maintainer" },
            { label: "Subscriber", value: "subscriber" },
        ];

        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];

        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
        ];
        const {
            fetchUsers,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            users,
            pagination,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
        } = useUsersList();

        const editModal = ref(false);
        const blankform = {
            badges: [],
        };
        const form = ref(JSON.parse(JSON.stringify(blankform)));
        const badges = ref(["Verification", "Celebrity", "Fashionista", "Corporate", "VIP"]);

        const sortDesc = ref(false);
        const sortDirection = ref("desc");

        onMounted(async () => {
            await fetchUsers().then((res) => {
                users.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
        });
        const paginateData = ref();
        const changePageUsers = async (paginate) => {
            paginateData.value = paginate;
            await fetchUsers(paginate).then((res) => {
                users.value.map((item) => {
                    return (item.created_at = item.created_at.slice(0, -9));
                });
            });
            await window.scrollTo(0, 0);
        };
        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            console.log(logedInUserPermissions.includes("User.list"));
            if (logedInUserPermissions.includes("User.list")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        const userId = ref();
        const editVerifyTickHandler = async (id) => {
            console.log(id);
            userId.value = id;
            editModal.value = true;
            const resource = await userRepository.show(id);
            form.value = resource;
        };
        const updateVerifyTick = async () => {
            console.log(userId.value);
            console.log(form.value.badges);
            userRepository.update(userId.value, { badges: form.value.badges }).then((res) => {
                fetchUsers(paginateData.value).then((res) => {
                    users.value.map((item) => {
                        return (item.created_at = item.created_at.slice(0, -9));
                    });
                });
            });
        };
        return {
            changePageUsers,
            users,
            pagination,
            tableColumns,
            perPage,
            currentPage,
            totalUsers,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refUserListTable,
            fetchUsers,
            // Filter
            avatarText,

            // UI
            resolveUserRoleVariant,
            resolveUserRoleIcon,
            resolveUserStatusVariant,

            roleOptions,
            planOptions,
            statusOptions,

            // Extra Filters
            roleFilter,
            planFilter,
            statusFilter,
            hasPermission,
            editModal,
            form,
            badges,
            editVerifyTickHandler,
            updateVerifyTick,
            sortDesc,
            sortDirection,
        };
    },
    computed: {
        ...mapGetters("modal", ["isModal"]),
        sortOptions() {
            // Create an options list from our fields
            return this.tableColumns.filter((f) => f.sortable).map((f) => ({ text: f.key, value: f.key }));
        },
    },
    data() {
        return {
            userId: null,
        };
    },
    methods: {
        async deleteUser(userId) {
            await userRepository.delete(userId);
            const index = this.users.findIndex((x) => x.id === userId);
            this.$delete(this.users, index);
        },
        openSendEmailModal(userId) {
            this.userId = userId;
            this.$_openModal({ name: "send_email" });
        },
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
    color: $red;
}
</style>
