import { SetPagination, SetQueriesObject } from '@/utils/setQueriesObject'

export const getJson = data => ({
    id: data.id,
    name: data.name,
    title: data.title,
    text: data.text,
})
export const getArray = ({ data, meta }) => {
    const pagination = SetPagination(meta)
    data = data.map(playList => getJson(playList))
    return { data, pagination }
}
export const setData = data => ({
    title: data.title,
    text: data.text,
})
export const setQuery = data => SetQueriesObject(data)
