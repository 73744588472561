<template>
  <div>
    <b-modal
        id="send-email-modal"
        name="modal_send_email"
        ok-only
        ok-title="Send"
        centered
        size="lg"
        title="Send Email"
        @show="setData"
        @ok="sendEmail"
    >
      <b-row>
        <!-- Email template -->
        <b-col cols="10">
          <b-form-group
              label="Template"
              label-for="template"
          >
            <b-form-select
                id="template"
                v-model="selectedTemplate"
                :options="templates"
                value-field="id"
                text-field="name"
                @change="templateSelected($event)"
            >
              <template #first>
                <b-form-select-option :value="null">Choose...</b-form-select-option>
              </template>

            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button
              variant="warning"
              :to="{ name: 'apps-email-templates-list'}"
              class="btn-icon mt-2"
          >
            <feather-icon
                icon="SettingsIcon"
                size="16"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <!-- Name -->
        <b-col cols="12">
          <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
          >
            <b-form-group
                label="Title"
                label-for="title"
            >
              <b-form-input
                  id="title"
                  v-model="form.title"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <!-- Description -->
        <b-col cols="12">
          <validation-provider
              name="Body"
          >
            <b-form-group
                label="Body"
                label-for="Body"
            >
              <b-form-textarea
                  v-model="form.body"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="Body"
                  rows="4"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-modal>
  </div>

</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BMediaAside,
  BMediaBody,
  BModal,
  BRow,
  BTab,
  BTabs,
  VBModal
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import EmailTemplateRepository from '@/abstraction/repository/emailTemplateRepository'
import EmailRepository from '@/abstraction/repository/emailRepository'

const emailTemplateRepository = new EmailTemplateRepository()
const emailRepository = new EmailRepository()

export default {
  name: 'send_email',
  components: {
    BButton,
    BCardText,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BFormInvalidFeedback,
    BTab,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BTabs,
    BCard,
    BAlert,
    BRow,
    BCol,
    vSelect,
    BMediaAside,
    BMediaBody,

    ValidationProvider,
    ValidationObserver,
    formValidation,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userId: null,
  },
  data(){
      return {
        selectedTemplate: null,
        templates: [{
          id: '', name: 'Choose...'
        }]
      }
  },
  setup() {
    let blankForm = {
      title: '',
      body: '',
    }

    const form = ref(JSON.parse(JSON.stringify(blankForm)))

    // Reset Validation
    const resetData = () => {
      form.value = JSON.parse(JSON.stringify(blankForm))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm
    } = formValidation(
        resetData,
    )

    return {
      form,
      resetData,
      resetForm,
      refFormObserver,
      formValidation,
      required,
      getValidationState,
    }
  },
  methods: {
    async setData() {
      this.templates = await emailTemplateRepository.index()
      console.log(this.templates)
    },
    async sendEmail() {
      await emailRepository.send(this.userId, this.form)
      this.form.value = JSON.parse(JSON.stringify(this.blankForm))
    },
    templateSelected(data){
      if (data){
        let template = this.templates.find(x => x.id == data)
        this.form.title = template.title
        this.form.body = template.text
      }else{
        this.form.title = ''
        this.form.body = ''
      }
    }
  }

}
</script>

<style scoped>

</style>
