import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/emailResource'

export default class EmailRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async send(id, data) {
        const json = setData(data)
        const response = await axios.post(
            url('sendEmail', { user: id }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }
}
